import React from 'react';
import ErrorNotification from '../../ErrorNotification/ErrorNotification';
import WeekPicker from '../WeekPicker/WeekPicker';
import { WeeklyTimetableLayoutViewModel } from '../../../ViewModel/bodyViewModel/bodyViewModel.types';
import { classes } from './WeeklyTimetableLayout.st.css';
import { DataHooks } from './constants';
// import WeekAvailability from '../WeekAvailability/WeekAvailability';
// import { AgendaSlotsViewModel } from '../../../ViewModel/agendaSlotsViewModel/agendaSlotsViewModel';

export interface WeeklyTimetableLayoutProps {
  viewModel: WeeklyTimetableLayoutViewModel;
  errorNotificationText: string;
}

const WeeklyTimetableLayout: React.FC<WeeklyTimetableLayoutProps> = ({
  viewModel: { weekPickerViewModel },
  errorNotificationText,
}) => {
  // const dailySlotsFactory = (
  //   date: string,
  //   slotsViewModel: AgendaSlotsViewModel,
  // ) => {
  //   return null;
  // };

  return (
    <div className={classes.root} data-hook={DataHooks.WeeklyTimetableLayout}>
      <ErrorNotification errorText={errorNotificationText} />
      <WeekPicker viewModel={weekPickerViewModel!} />
      {/*<WeekAvailability*/}
      {/*  slotsPerDays={slotsPerDays!}*/}
      {/*  slotsStatus={slotsStatus}*/}
      {/*  dailySlotsFactory={dailySlotsFactory}*/}
      {/*/>*/}
    </div>
  );
};

export default WeeklyTimetableLayout;
